<template>
  <div :class="$style.orderConfirmation">
    <div v-if="initStatusSuccess" :class="$style.orderSuccessMessageCard">
      <div :class="$style.successIconCircle">
        <BaseIcon
          icon="check"
          size="lg"
          color="#10b981"
          :class="$style.successIcon"
        />
      </div>
      <h1 :class="$style.headline">Thank you for your purchase.</h1>

      <div v-if="user">
        <div>We've sent you an email with purchase confirmation.</div>
        <div :class="$style.accessProductsBtnContainer">
          <BaseButton
            tag="router-link"
            :to="`/dashboard${productId ? `/product/${productId}` : ''}`"
            variant="primary"
            >Access Product{{ productId ? "" : "s" }}</BaseButton
          >
        </div>
      </div>
      <div v-else>
        We've sent you an email with purchase confirmation and a login link, so
        you can access your product.
      </div>
    </div>
  </div>
</template>

<script>
import { getUserEmailByStripeSessionId } from "@/api/userApi";
import { withAsync } from "@/helpers";
import { sendEmailLoginLink, onAuthStateChanged } from "@/api/authApi";
import { IDLE, PENDING, SUCCESS } from "@/constants/apiStatus";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { fetchUserPurchaseBySessionId } from "@/api/purchaseApi";
import { fetchProductById } from "@/api/productApi";
export default {
  data() {
    return {
      user: null,
      initStatus: IDLE,
      productId: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("initStatus"),
  },
  async created() {
    const sessionId = this.$route.query.session_id;
    if (!sessionId) {
      this.$router.push("/");
    } else {
      await this.initOrderConfirmation(sessionId);
      this.fetchPurchasedProduct(sessionId);
    }
  },
  methods: {
    async fetchPurchasedProduct(sessionId) {
      const purchase = await fetchUserPurchaseBySessionId(
        this.user.uid,
        sessionId
      );
      if (!purchase) return;
      this.productId = purchase.productId;
    },
    async initOrderConfirmation(sessionId) {
      this.initStatus = PENDING;
      const user = await onAuthStateChanged();
      if (user) {
        this.user = user;
        this.initStatus = SUCCESS;
      } else {
        await this.initSendLoginLink(sessionId);
        this.initStatus = SUCCESS;
      }
    },
    async initSendLoginLink(sessionId) {
      if (localStorage.getItem("purchase-login-email-sent")) return;
      const { response, error } = await withAsync(() =>
        getUserEmailByStripeSessionId(sessionId)
      );
      console.log("?", response, error);

      if (error) {
        console.error(error);
        this.$router.push("/");
        return;
      }
      const { email } = response;
      await sendEmailLoginLink(email, {
        redirect: "/dashboard",
      });
      localStorage.setItem("purchase-login-email-sent", true);
    },
  },
};
</script>

<style lang="scss" module>
.orderConfirmation {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.6;
}

.accessProductsBtnContainer {
  margin-top: 1rem;
}

.orderSuccessMessageCard {
  max-width: 35rem;
}

.successIconCircle {
  border-radius: 50%;
  border: 0.4rem solid #10b981;
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.successIcon {
  font-size: 2.4rem;
}

.headline {
  font-size: 2rem;
  margin: 2rem 0;
}
</style>