import { db } from '@/config/firebase.config.js';

export const fetchUserPurchaseBySessionId = async (userId, sessionId) => {
  const result = await db
    .collection('purchases')
    .where('userId', '==', userId)
    .where('sessionId', '==', sessionId)
    .get();

  if (!result.docs.length) return [];
  return result.docs[0].data();
};
